import 'bootstrap/dist/css/bootstrap.css'
import './css/stylish-portfolio.css'
import '@fortawesome/fontawesome-free-webfonts';
import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';
import Vue from 'vue';
import App from './App.vue';
import vueSmoothScroll from 'vue-smooth-scroll'

Vue.use(vueSmoothScroll)


new Vue({
  el: '#app',
  render: h => h(App)
});
